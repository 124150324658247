import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {environment} from '../environments/environment';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ToastrModule} from 'ngx-toastr';
import {ApplicationSessionService} from '@services/application-session.service';
import {TawkComponent} from './shared-modules/tawk/tawk/tawk.component';
import {FormsModule} from '@angular/forms';
import {CustomDatePipe} from '@grants/services/custom-date.pipe';
import {HttpClientModule} from '@angular/common/http';
import {TreeviewModule} from 'ngx-treeview';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';


const ROUTE_DEBUG_TRACING = false;
const ROUTES: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/app/landing'},
  {path: 'app', loadChildren: () => import('./mabble-instance/mabble-instance.module').then(m => m.MabbleInstanceModule)},
  {path: 'authentication', redirectTo: '/app/authentication'},
  {path: 'error', redirectTo: '/app/error'},

  {path: '**', redirectTo: '/app/error/404'}
];

@NgModule({
  declarations: [
    AppComponent,
    TawkComponent,
    CustomDatePipe
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(ROUTES, {
      enableTracing: ROUTE_DEBUG_TRACING,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled'
    }),
    TreeviewModule.forRoot(),
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF
    }),

    NgbModule,
    ToastrModule.forRoot({
      timeOut: 0,
      closeButton: true,
    }),
    LeafletModule
  ],
  entryComponents: [],
  providers: [ApplicationSessionService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
