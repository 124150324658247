import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'customDate'})
export class CustomDatePipe implements PipeTransform {

  transform(date: any): any {
    if (typeof date === 'object') {
      return date.day + '-' + date.month + '-' + date.year;
    } else {
      return date;
    }
  }
}
