import * as _ from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {
  ApplicationApi,
  ApplicationSession,
  ApplicationSessionUser,
  MabbleModule,
} from '@core/models/application-session.model';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

/**
 * mzs: 2017-11-14.120612
 * mzs: 2017-12-27.153858
 * mzs: 2018-01-05.070827
 * ...
 * */
export class SessionEvent {
  _onion: number;
  activeRecord?: any;
  event: string;
  key: string;
  source: any;
  module?: MabbleModule<any>;

  constructor(options?: any) {
    this._onion = (this._onion || options && options._onion || 0) + 1;
    this.activeRecord = options && options.activeRecord;
    this.event = options && options.event;
    this.key = options && options.key;
    this.source = options && options.source;
    this.module = options && options.module;

    if (this.source && this.source._onion) {
      this._onion = this._onion + this.source._onion;
    }

  }


}

export class WatcherStore {
  private _map: [{ [p: string]: BehaviorSubject<any> }];

  constructor(root: object) {
    this._map = [{
      root: new BehaviorSubject(root)
    }];
  }

  next(key: string, value?: any): BehaviorSubject<any> {
    const reply = this.get(key);
    if (value) {
      reply.next(value);
    }
    return reply;
  }

  private get(key: string): BehaviorSubject<any> {
    const _m = this._map[key];
    if (_m) {
      return _m;
    }
    this._map[key] = new BehaviorSubject({});
    return this._map[key];
  }
}

@Injectable()
export class ApplicationSessionService {
  private _watchers: WatcherStore = new WatcherStore({});

  private application: ApplicationSession = new ApplicationSession({});
  private anonymous: ApplicationSessionUser;

  private admin_roles: string[] = ['ROLE_ADMINISTRATOR', 'ROLE_SYS_ADMIN'];
  private granted_partner_role = 'ROLE_GRANTED_PARTNER';
  private cgsa_coordinator_role = 'ROLE_CGSA_COORDINATOR';
  private cgsa_admin_role = 'ROLE_CGSA_ADMIN';
  loading = true;

  constructor() {
    this.fetch('root-application-session.seed.json', (rootdata) => {
      this.fetch(environment.seed, (instancedata) => {

        const seed = _.merge({}, rootdata, instancedata, {
          enter_any_emergency_override_here: false
        });

        this.application = new ApplicationSession(seed);

        // prepare a singleton watcher store
        this._watchers = new WatcherStore(this.application);

        // init session wide roles
        this.admin_roles = ['ROLE_ADMINISTRATOR', 'ROLE_SYS_ADMIN'];
        if (this.application.defaults.admin_roles) {
          this.application.defaults.admin_roles.forEach(ar => this.admin_roles.push(ar));
        }

        // publish the session
        this.fire();
        this.loading = false;
      })
    });
  }

  get adminRoles(): string[] {
    return [...this.admin_roles];
  }

  get grantedPartnerRole(): string {
    return this.granted_partner_role;
  }

  get cgsaCoordinatorRole(): string {
    return this.cgsa_coordinator_role;
  }

  get cgsaAdminRole(): string {
    return this.cgsa_admin_role;
  }

  public fire() {
    this._watchers.next('menu', this.application.menu);
    this._watchers.next('session.api', this.application.api);
    this._watchers.next('mabbleUser', this.application.mabbleUser);
    this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);

    this._watchers.next('_root', this.application);
  }


  public set(key: string, value: any) {
    switch (key) {
      case('session.mabble.host.auth.token'): {
        this.application.mabbleUser.auth_token = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        break;
      }
      case ('mabbleUser'):
      case('session.mabble.host.user'): {
        this.application.mabbleUser = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        break;
      }
      case ('mabbleUser.data'):
      case ('session.mabble.host.user.data'): {
        this.application.mabbleUser.data = value;
        this._watchers.next('mabbleUser', this.application.mabbleUser);
        this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        break;
      }
      default: {
        this._watchers.next(key).getValue();
      }
    }
    this._watchers.next('_root', this.application);
  }


  public get(key: string): any {
    switch (key) {
      case (''): {
        break;
      }
      case ('api'): {
        return this.application.api;
      }
      case ('apiHost'):
      case ('api.host'): {
        return this.application.api.host;
      }
      case ('mabbleUser.data'): {
        return this.application.mabbleUser.data;
      }
      case ('default.home.route'): {
        return this.application.defaults.home.route;
      }
      case ('session'): {
        return this.application;
      }
      case ('session.mabble.host.user'): {
        return this.application.mabbleUser;
      }
      default: {
      }
    }
  }


  public observe(key: string): BehaviorSubject<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> |
    Observable<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> {
    switch (key) {
      case ('api') :
      case ('session.api') : {
        return this._watchers.next(key, this.application.api);
      }
      case ('session.m_api') : {
        return this._watchers.next(key, this.application.m_api);
      }
      case ('apiHost') :
      case ('api.host') : {
        return of(this.application.api.host);
      }
      case ('login.page') : {
        return of(this.application.login.page);
      }
      case ('menu') : {
        return this._watchers.next('menu', this.application.menu);
      }
      case ('is-authenticated') :
      case ('users') :
      case ('session.mabble.host.user') : {
        return this._watchers.next('mabbleUser', this.application.mabbleUser);
      }
      case ('session.mabble.host.user.data') :
      case ('mabbleUser.data') : {
        if (this.application.mabbleUser && this.application.mabbleUser.data
          && this.application.mabbleUser.auth_token && this.application.mabbleUser.auth_token.length > 0) {
          return this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        } else {
          return this._watchers.next('mabbleUser.data', this.anonymous.data);
        }
      }
      case ('session') :
      default: {
        return this._watchers.next('_root');
      }
    }
  }


  public subscribe(key: string): BehaviorSubject<ApplicationSession | ApplicationApi | ApplicationSessionUser | any> {
    switch (key) {
      case ('menu') : {
        return this._watchers.next('menu', this.application.menu);
      }
      case ('is-authenticated') :
      case ('users') :
      case ('session.mabble.host.user') : {
        return this._watchers.next('mabbleUser', this.application.mabbleUser);
      }
      case ('mabbleUser.data') :
      case ('session.mabble.host.user.data') : {
        if (this.application.mabbleUser && this.application.mabbleUser.data
          && this.application.mabbleUser.auth_token && this.application.mabbleUser.auth_token.length > 0) {
          return this._watchers.next('mabbleUser.data', this.application.mabbleUser.data);
        } else {
          return this._watchers.next('mabbleUser.data', this.anonymous.data);
        }
      }
      case ('session') :
      default: {
        return this._watchers.next('_root', this.application);
      }
    }
  }


  private fetch(datkey: string, cb: any) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/seed/' + datkey);
    req.onload = () => cb(JSON.parse(req.response));
    req.send();
  }

}
